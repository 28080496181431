const languageData = {
	EN: {
		title: 'Login to UlkePuan',
		subtitle:
			'Log in to comment on UlkePuan blogs and join the RanKKing game.',
		countryPointsTitle: 'What is Country Points?',
		countryPointsSubtitle:
			'This video answers all your questions about how many points for victory in the Champions League, bonuses for qualifying for the Conference League groups, and how many bonus points leadership brings.',

		rankingSubtitle:
			'Do you think you know European football better than anyone else? Here’s your chance to prove it! We invite you to participate in our RanKKing prediction game and win amazing prizes. 🏆', // Updated format
		championsLeagueTitle: 'Champions League New Format',
		championsLeagueSubtitle:
			'The number of teams increases from 32 to 36. It is very likely that two of the extra four slots will go to the four major countries. One slot is given to the 5th country, namely France, while the last slot has been given to the ‘Champions Path’ qualification in which our champion will also participate.',
		updateTitle: 'Country Points Update on September 27, 2024',
		globalPointsTitle: 'Global Country Points',
		globalPointsSubtitle:
			'You can find the data of the top 10 teams that have collected the most points in European league organizations in the last 20 years below.',
	},
	TR: {
		title: 'UlkePuan Giriş Yap',
		subtitle:
			'UlkePuan bloglara yorum yapmak, RanKKing oyununa katılmak için giriş yap.',
		countryPointsTitle: 'Ülke Puanı Nedir?',
		countryPointsSubtitle:
			"Şampiyonlar Ligi'nde galibiyet kaç puan, Konferans Ligi'nde gruplara kalma için bonus var mı, liderlik kaç bonus puan getiriyor gibi tüm soruların cevabı bu videoda yer alıyor.",
		rankingSubtitle:
			'Avrupa futbolunu herkesten daha iyi bildiğinizi mi düşünüyorsunuz? İşte bunu kanıtlama şansınız! Sizi RanKKing tahmin oyunumuza katılmaya ve harika ödüller kazanmaya davet ediyoruz. 🏆', // Updated format
		championsLeagueTitle: 'Şampiyonlar Ligi Yeni Format',
		championsLeagueSubtitle:
			"Artan Takım Sayısı İlk olarak takım sayısı 32'den 36'ya çıkıyor. Ekstra 4 kontenjandan ikisinin dört büyük ülkeden ikisine gitmesi çok olası. Bir kontenjan 5.ülkeye yani Fransa'ya verilirken son kontenjan ise bizim şampiyonumuzun da katılacağı 'Şampiyonlar Yolu' elemesine verilmiş durumda.",
		updateTitle: '27 Eylül 2024 Ülke Puanı Güncellemesi',
		globalPointsTitle: 'Global Ülke Puanları',
		globalPointsSubtitle:
			'Son 20 yılda Avrupa ligi organizasyonlarına katılan takımlardan en çok puan toplayan ilk 10 takımların verilerine aşağıdan ulaşabilirsiniz.',
	},
};

export default languageData;
