// src/App.js
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from './store/index'; // Assuming you have the store setup in redux/store.js
import MainRouter from './navigation'; // Your MainRouter component for routing

function App() {
	useEffect(() => {
		(function () {
			var devtools = function () {};
			devtools.toString = function () {
				console.log('Geliştirici araçlarını kapatın!');
				return '';
			};
			console.log('%c', devtools);
		})();
	}, []);
	return (
		<Provider store={store}>
			<MainRouter />
		</Provider>
	);
}

export default App;
