// src/redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Check if user is saved in localStorage
const savedUser = JSON.parse(localStorage.getItem('user')) || null;

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: savedUser,
		isAuthenticated: !!savedUser,
	},
	reducers: {
		loginSuccess: (state, action) => {
			state.user = action.payload;
			state.isAuthenticated = true;
			localStorage.setItem('user', JSON.stringify(action.payload)); // Save user to localStorage
		},
		logout: (state) => {
			state.user = null;
			state.isAuthenticated = false;
			localStorage.removeItem('user'); // Remove user from localStorage
		},
	},
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
