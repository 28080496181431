import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleAd from '../components/GoogleAd'; // Import GoogleAd component
import ReactPlayer from 'react-player/lazy';
import '../styles/main.css';
import Page from '../components/Page';
import CommentSection from '../components/CommentSection';
import Rankking from '../assets/rankking.png';
import Uefa from '../assets/uefa.jpg';
import LoginImage from '../assets/login.png';
import TeamPointsChart from '../components/TeamPointsChart';
import TeamPointsTable from '../components/TeamsPointsTable';
import languageData from '../context/mainLanguage'; // Assuming the JSON data is stored here
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { logout } from '../store/authSlice';
import { FiLogOut } from 'react-icons/fi';
import { useEffect } from 'react';

const MainScreen = () => {
	const navigate = useNavigate();
	const { language } = useSelector((state) => state.language);
	const dispatch = useDispatch();
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		const auth = getAuth();
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				setIsLoggedIn(true); // Kullanıcı giriş yapmışsa
			} else {
				setIsLoggedIn(false); // Giriş yapmamışsa
			}
		});

		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, []);

	const handleLogout = () => {
		const auth = getAuth(); // Firebase auth instance
		signOut(auth)
			.then(() => {
				dispatch(logout());

				// Re-render the component after logout
				setIsLoggedIn(false);

				// Alert immediately after setting the message
				alert('Oturum başarıyla kapatıldı.');

				// Navigate to the login screen or home page
				navigate('/');
			})
			.catch((error) => {
				// Handle errors and set error message
				console.error('Error during sign out:', error);
				console.error(
					'Oturum kapatma işlemi sırasında bir hata oluştu.'
				);

				// Optionally show an error alert
				alert('Oturum kapatma işlemi sırasında bir hata oluştu.');
			});
	};

	const rankGame = () => {
		navigate('/rank-king');
	};
	const loginPage = () => {
		if (isLoggedIn) {
			alert('User all ready logged in.');
		} else {
			navigate('/login');
		}
	};
	const dataPage = () => {
		navigate('/country-score'); // Fixed typo here
	};
	const handleSectionSelect = (section) => {
		console.log(`Selected section: ${section}`);
	};
	const tweetId = '1839410677816938736'; // Replace this with your tweet ID
	const logo =
		'https://pbs.twimg.com/profile_images/1563266428089671681/7ZH-1Yzz_400x400.jpg';

	// Language texts
	const texts = languageData[language];

	return (
		<div className='mainScreen dark'>
			<div className='mainScreen-container'>
				<header className='mainScreen-header-container'>
					<h1 className='mainScreen-header-title'>{texts.title}</h1>
					<p className='mainScreen-header-subtitle'>
						{texts.subtitle}
					</p>
					<img
						onClick={loginPage}
						src={LoginImage}
						className='mainScreen-rankking'
					/>
				</header>
				<header className='mainScreen-header-container'>
					<h1 className='mainScreen-header-title'>
						{' '}
						{texts.countryPointsTitle}
					</h1>
					<p className='mainScreen-header-subtitle'>
						{texts.countryPointsSubtitle}
					</p>
					<div className='mainScreen-video'>
						<ReactPlayer
							className='video-try'
							url='https://www.youtube.com/watch?v=VU1syG0Uwjc&t=212s'
						/>
					</div>
				</header>
				<header className='mainScreen-header-container'>
					<h1 className='mainScreen-header-title'>
						RanK<span className='reverse-k'>K</span>ing
					</h1>
					<p className='mainScreen-header-subtitle'>
						{texts.rankingSubtitle}
					</p>
					<img
						onClick={rankGame}
						src={Rankking}
						className='mainScreen-rankking'
					/>
				</header>
				<header className='mainScreen-header-container'>
					<h1 className='mainScreen-header-title'>
						{texts.championsLeagueTitle}
					</h1>
					<p className='mainScreen-header-subtitle'>
						{texts.championsLeagueSubtitle}
					</p>
					<div className='mainScreen-video'>
						<ReactPlayer url='https://www.youtube.com/watch?v=Jg8gZT1691k' />
					</div>
				</header>
				<header className='mainScreen-header-container'>
					<h1 className='mainScreen-header-title'>
						{texts.updateTitle}
					</h1>
					<Page tweetId={tweetId} />
				</header>
				<header className='mainScreen-header-container'>
					<h1 className='mainScreen-header-title'>
						{texts.globalPointsTitle}
					</h1>
					<p className='mainScreen-header-subtitle'>
						{texts.globalPointsSubtitle}
					</p>
					<img
						onClick={dataPage}
						src={Uefa}
						className='mainScreen-rankking'
					/>
				</header>

				<CommentSection />
			</div>
			{isLoggedIn && (
				<button onClick={handleLogout} className='main-logout-button'>
					<FiLogOut size={44} color='white' />
				</button>
			)}
		</div>
	);
};

export default MainScreen;
