const translations = {
	EN: {
		title: 'Game Guide: Country Score Prediction Game',
		purpose:
			"Purpose: Players try to predict the ranking of teams in UEFA's 3 main leagues (UCL - UEFA Champions League, UEL - UEFA Europa League, UECL - UEFA Europa Conference League) by predicting which team will bring the most points to their country by the end of the season. Players can rank the 36 teams in each league and save their predictions at the end of the game.",
		rulesTitle: '1. Game Rules:',
		leagueSelection:
			'League Selection: Players can select from UCL, UEL, and UECL leagues. There is no obligation to make predictions for every league. Players can predict in just one or two leagues if they wish.',
		teamSelection:
			'Team Selection and Ranking: Each league has exactly 36 teams, and these teams must be ranked completely. You can use the drag-and-drop method to rearrange the selected teams. You cannot save predictions for a league without ranking all 36 teams.',
		sendUpdate: '2. Send and Update:',
		sendExplanation:
			'Send: Once your ranking for a league is complete, you can save your predictions by clicking the "Send" button. Only the completed league will be sent.',
		updateExplanation:
			'Update: If you have already made predictions for a league and want to change them, you can update your rankings. The "Update" button only saves the changes made to the current league, and the other league data remains unchanged.',
		dataHandling: '3. Switching Between Leagues and Data Handling:',
		dataSwitchingExplanation:
			'Switching Between Leagues: You can freely switch between UCL, UEL, and UECL leagues. When you switch, the rankings from the database are retrieved and displayed for the current league. Data from other leagues remains intact.',
		dataProtection:
			"Data Protection: Each league's ranking is independent. Changes made in one league do not affect the others.",
		dragDropTitle: '4. Drag-and-Drop to Rank Teams:',
		dragDropExplanation:
			'Drag-and-Drop: You can rearrange the 36 selected teams by dragging and dropping them. You cannot modify empty slots, only the selected teams.',
		errorsAndWarnings: '5. Error Messages and Warnings:',
		errorsExplanation:
			'Missing or Extra Teams: If there are not exactly 36 teams selected for a league, you will be notified with an error message and your rankings will not be saved. You will also be notified of any errors or missing changes.',
		noChangeWarning:
			'No Changes Detected: If no changes have been made to the rankings, you will receive a "No changes detected" warning when you click the "Update" button.',
		clearFunction: '6. Clear Function:',
		clearExplanation:
			'Clear: You can reset the rankings and selections for a league using the "Clear" button. This only resets the rankings on the screen, the data already sent to the database remains intact.',
		gameTimeAndPrizes: '7. Game Duration and Prizes:',
		gameTimeExplanation:
			'Game Duration: You must complete your rankings by September 17th. After this date, no further changes will be allowed.',
		prizesExplanation:
			'Prizes: At the end of all leagues, the players who make the most accurate predictions will receive prizes for first, second, and third places. You will also be able to see a weekly leaderboard showing the best predictions. For more information on prizes and the leaderboard, follow the Twitter account "@UlkePuan".',
		strategyTitle: '8. Strategy:',
		strategyExplanation:
			"Review your rankings based on the teams' performance and try to make the most accurate predictions. After completing your rankings using the drag-and-drop method, save your predictions.",
		puanlamaTitle: '9. Scoring and Points Calculation:',
		puanlamaExplanation:
			'When calculating points, the difference between your prediction for each team and their actual ranking in the European leagues is calculated. This calculation is performed for all teams in the league you predicted. The difference between the predicted ranking and the actual standings for each team is subtracted from the initial 1260 points. At the end of the game, the person with the highest score as of week 8 (or week 6 for the Conference League) will win the game. However, we will share these calculations on a weekly basis, even though it does not have a significant impact in terms of the final prize.',
		puanlamaExampleTitle: 'Example:',
		puanlamaExample:
			'You predicted Real Madrid would finish 1st. However, Real Madrid finished 3rd in the league stage. In this case, you will lose 2 points. Similarly, if you predicted Manchester City would finish 5th, and Manchester City finished 2nd, you will lose 3 points. Your personal score will be calculated by subtracting the losses for all 36 teams.',
		rules2Title: '10. Additional Rules:',
		rules2Explanation:
			'You must enter your predictions before the first Champions League match starts, specifically by 19:45 (Turkish time) on September 17, 2024. Predictions cannot be changed after the league stage has started. The interim ranking does not matter for the prize. The person with the most accurate prediction at the end of the league stage will be selected as the 1st place. After each league week, the ranking will be accessible from the website. We will also share updates about the top users on the @ulkepuan Twitter account.',
		closeButton: 'Close',
	},
	TR: {
		title: 'Oyun Kılavuzu: Ülke Puanı Tahmin Oyunu',
		purpose:
			"Amaç: Kullanıcılar, UEFA'nın 3 ana liginden (UCL - UEFA Champions League, UEL - UEFA Europa League, UECL - UEFA Europa Conference League) takımların sıralamasını tahmin ederek, hangi takımın sezon sonunda ülkelerine en fazla puanı kazandıracağını öngörmeye çalışır. Her ligdeki 36 takımı sıralayıp, doğru tahminlerde bulunarak oyun sonunda tahminlerini sisteme kaydedebilirler.",
		rulesTitle: '1. Oyun Kuralları:',
		leagueSelection:
			'Lig Seçimi: Oyuncular, UCL, UEL ve UECL liglerinden istediklerini seçebilirler. Her lig için sıralama yapma zorunluluğu yoktur. Kullanıcılar, dilerlerse yalnızca bir veya iki ligde tahmin yapabilirler.',
		teamSelection:
			'Takım Seçimi ve Sıralama: Her ligde tam 36 takım vardır ve bu takımlar eksiksiz bir şekilde sıralanmalıdır. Seçilen takımlar arasında sürükle-bırak yöntemi kullanarak sıralama yapılabilir. 36 takımı sıralamadan o ligin verileri kaydedilemez.',
		sendUpdate: '2. Gönder ve Güncelle:',
		sendExplanation:
			'Gönder: Bir ligdeki sıralamanız tamamlandığında, "Gönder" butonuna tıklayarak tahminlerinizi kaydedebilirsiniz. Sadece tamamladığınız lig için gönderim yapılır.',
		updateExplanation:
			'Güncelle: Eğer bir ligde daha önce sıralamalar yaptıysanız ve bu sıralamalarda değişiklik yapmak isterseniz, sıralamanızı güncelleyebilirsiniz. "Güncelle" butonu sadece değişiklik yaptığınız ligin verilerini kaydeder, diğer liglerdeki veriler değişmeden kalır.',
		dataHandling: '3. Ligler Arası Geçiş ve Veri Yönetimi:',
		dataSwitchingExplanation:
			'Ligler Arası Geçiş: UCL, UEL ve UECL ligleri arasında serbestçe geçiş yapabilirsiniz. Hangi ligde işlem yapıyorsanız, o ligdeki sıralamalar veritabanından alınarak ekranda gösterilir. Bu sırada diğer liglerdeki kaydettiğiniz veriler kaybolmaz.',
		dataProtection:
			'Veri Koruma: Her ligin sıralaması bağımsızdır. Bir ligde yaptığınız değişiklikler, diğer liglerdeki sıralamaları etkilemez.',
		dragDropTitle: '4. Takımları Sürükle-Bırak ile Sıralama:',
		dragDropExplanation:
			'Sürükle-Bırak: Seçtiğiniz 36 takımı listedeki yerlerinden sürükleyerek sıralayabilirsiniz. Ancak boş slotlar üzerinde işlem yapılamaz. Sadece seçili takımlar arasında değişiklik yapılabilir.',
		errorsAndWarnings: '5. Hata Mesajları ve Uyarılar:',
		errorsExplanation:
			'Eksik veya Fazla Takım Seçimi: Eğer bir ligde 36 takım seçilmemişse, hata mesajı ile bilgilendirilirsiniz ve sıralamanız kaydedilmez. Herhangi bir değişiklik yapılmadığında ya da sıralamada hata olduğunda da uygun uyarı mesajları alırsınız.',
		noChangeWarning:
			'Değişiklik Tespiti: Eğer sıralamada herhangi bir değişiklik yapılmadıysa, "Güncelle" butonuna bastığınızda "Herhangi bir değişiklik tespit edilmedi" uyarısı alırsınız.',
		clearFunction: '6. Clear (Temizleme) Fonksiyonu:',
		clearExplanation:
			'Clear: "Clear" butonunu kullanarak, o ligdeki sıralamayı ve seçimleri sıfırlayabilirsiniz. Ancak bu işlem sadece ekrandaki sıralamayı sıfırlar, veritabanına gönderilen veriler korunur.',
		gameTimeAndPrizes: '7. Oyun Süresi ve Ödüller:',
		gameTimeExplanation:
			'Oyun Süresi: Tüm sıralamalarınızı 17 Eylül tarihine kadar tamamlamanız gerekmektedir. Bu tarihten sonra değişiklik yapamayacaksınız.',
		prizesExplanation:
			'Ödüller: Tüm ligler tamamlandıktan sonra en doğru tahmini yapan birinci, ikinci ve üçüncüye ödüller verilecektir. Ayrıca, haftalık liderlik tablosu ile her hafta en iyi tahmin yapan kullanıcıları görebileceksiniz. Ödüller ve liderlik tablosu hakkında daha fazla bilgi almak için "@UlkePuan" Twitter hesabını takip edin.',
		strategyTitle: '8. Strateji:',
		strategyExplanation:
			'Takımların performanslarına göre sıralamalarınızı gözden geçirin ve en doğru tahmini yapmaya çalışın. Sürükle-bırak yöntemiyle sıralamanızı tamamladıktan sonra tahminlerinizi kaydedin.',
		puanlamaTitle: '9. Puanlama ve Hesaplama:',
		puanlamaExplanation:
			'Puanlama hesaplanırken, her takım için yaptığınız tahmin ile Avrupa liglerindeki gerçek sıralamalar arasındaki fark alınarak bir hesaplama yapılır. Bu hesaplama, tahmin ettiğiniz ligdeki tüm takımlar için gerçekleştirilir. Her takım için tahmin edilen sıralama ile gerçek puan durumu arasındaki fark, başlangıçta verilen 1260 puandan düşülür. Oyunun sonunda, sekizinci hafta (Konferans Ligi için 6. hafta) itibarıyla en yüksek puana sahip olan kişi oyunu kazanır. Ancak, ödül açısından bir anlamı olmasa da her hafta bu hesaplamaları paylaşacağız.',
		puanlamaExampleTitle: 'Örnek:',
		puanlamaExample:
			'Real Madrid için 1. sırada bitireceği yönünde bir tahminde bulundunuz. Ancak, Real Madrid lig aşamasında 3. sırada yer aldı. Bu durumda, 2 puan kaybedeceksiniz. Benzer şekilde, Manchester City için 5. sırada bitireceği yönünde tahmin yaptınız ve Manchester City lig aşamasında 2. sırada yer aldı. Bu durumda, Manchester City için 3 puan kaybı yaşayacaksınız. Bu şekilde 36 takım için de ayrı ayrı puan kayıpları hesaplanarak kişisel skorunuz ortaya çıkacaktır.',
		rules2Title: '10. Ek Kurallar:',
		rules2Explanation:
			"Tahminlerinizi, ilk Şampiyonlar Ligi maçı başlamadan önce, yani TSİ 17 Eylül 2024 19:45'e kadar girmeniz gerekmektedir. Tahminler, lig aşaması başladıktan sonra değiştirilemez. Ödül açısından ara haftalarda sıralamada kaçıncı olduğunuzun bir önemi yoktur. Lig aşamasının son haftasında en doğru tahmin eden kişi 1. seçilecektir. Her lig haftasından sonra sıralama, siteden erişilebilir olacak. Ayrıca, @ulkepuan Twitter hesabından zirvedeki kullanıcılarla ilgili paylaşımlar yapılacaktır.",
		closeButton: 'Kapat',
	},
};

export default translations;
