// languageData.js
const chartLanguage = {
	TR: {
		topTeamsTitle: 'Son 20 Yılda En Çok Puan Toplayan 10 Takım',
		// Add other texts as needed...
	},
	EN: {
		topTeamsTitle: 'Top 10 Teams with Most Points in the Last 20 Years',
		// Add other texts as needed...
	},
};

export default chartLanguage;
