// src/components/Footer.js
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../styles/footer.css';

const Footer = () => {
	const navigate = useNavigate();

	const { language } = useSelector((state) => state.language);
	const translations = {
		EN: {
			support: 'Support',
			privacy: 'Privacy Policy',
			ulkepuaninfo: '© 2024 UlkePuan all rights reserved',
		},
		TR: {
			support: 'Destek',
			privacy: 'Gizlilik Politikası',
			ulkepuaninfo: '© 2024 UlkePuan tüm hakları saklıdır',
		},
	};
	const t = translations[language];
	const goTwitter = () => {
		window.open('https://x.com/UlkePuan', '_blank');
	};
	const goInstagram = () => {
		window.open('https://www.instagram.com/ulkepuan/', '_blank');
	};
	const goYoutube = () => {
		window.open('https://www.youtube.com/@ulkepuan', '_blank');
	};
	const PrivacyPolicy = () => {
		navigate('/privacy-policy');
	};
	return (
		<div className='footer'>
			<p className='login-footer-text'>{t.ulkepuaninfo}</p>
			<p className='login-footer-text' onClick={PrivacyPolicy}>
				{t.privacy}
			</p>
			<a href='mailto:destek@ulkepuan.net' className='login-footer-text'>
				{t.support}
			</a>

			<p className='login-footer-text' onClick={goTwitter}>
				Twitter
			</p>
			<p className='login-footer-text' onClick={goInstagram}>
				Instagram
			</p>
			<p className='login-footer-text' onClick={goYoutube}>
				Youtube
			</p>
		</div>
	);
};

export default Footer;
