import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../styles/notfound.css';

const NotFound = () => {
	const navigate = useNavigate();
	const { isAuthenticated } = useSelector((state) => state.auth);
	const [redirecting, setRedirecting] = useState(false); // State to control redirection

	useEffect(() => {
		const timer = setTimeout(() => {
			if (isAuthenticated) {
				navigate('/'); // Redirect to HomeScreen if logged in
			} else {
				navigate('/'); // Redirect to LoginScreen if not logged in
			}
			setRedirecting(true);
		}, 3000); // 3-second delay to show NotFound page

		return () => clearTimeout(timer); // Cleanup timer on component unmount
	}, [isAuthenticated, navigate]);

	if (redirecting) {
		return null; // Do not show anything when redirecting
	}

	return (
		<div className='notfound-container'>
			<h1>404</h1>
			<p>Page Not Found</p>
			<p>You will be redirected shortly...</p>
			{/* Optional: You can add a manual button to redirect immediately */}
			<button onClick={() => navigate(isAuthenticated ? '/' : '/login')}>
				Go Home
			</button>
		</div>
	);
};

export default NotFound;
