import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Import useSelector
import '../styles/main.css';
import TeamPointsChart from '../components/TeamPointsChart';
import TeamPointsTable from '../components/TeamsPointsTable';
import languageData from '../context/dataScreenLanguage'; // Ensure this path is correct

const DataScreen = () => {
	const navigate = useNavigate();
	const { language } = useSelector((state) => state.language);
	const texts = languageData[language]; // Get texts based on selected language

	return (
		<div className='mainScreen dark'>
			<div className='mainScreen-container'>
				<header className='mainScreen-header-container'>
					<h1 className='mainScreen-header-title'>
						{texts.countryScoreTitle}
					</h1>
					<p className='mainScreen-header-subtitle'>
						{texts.countryScoreSubtitle}
					</p>
					<p className='mainScreen-header-subtitle'>
						{texts.moreInfoText}
						<a
							href='https://kassiesa.net/uefa/calc.html'
							target='_blank'
							rel='noopener noreferrer'
							style={{
								color: '#007bff',
								textDecoration: 'underline',
							}} // Optional: Add style for the link
						>
							{texts.linkText}
						</a>
						{texts.footerText}
					</p>
				</header>
				<header className='mainScreen-header-container'>
					<TeamPointsTable />
				</header>
				<header className='mainScreen-header-container'>
					<TeamPointsChart />
				</header>
			</div>
		</div>
	);
};

export default DataScreen;
