import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';

import { getAuth, TwitterAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyALJGg8U4Gz_NoNgO-Dspw4MpsA7rU-niI',
	authDomain: 'ulkepuan.net',
	databaseURL:
		'https://ulkepuani-92371-default-rtdb.europe-west1.firebasedatabase.app', // Güncellenmiş URL
	projectId: 'ulkepuani-92371',
	storageBucket: 'ulkepuani-92371.appspot.com',
	messagingSenderId: '1094467090264',
	appId: '1:1094467090264:web:00688807ec215150d0572d',
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);
const firestore = getFirestore(app); // Firestore
const db = getFirestore(app);

export { database, auth, TwitterAuthProvider, signInWithPopup, firestore, db };
