import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	collection,
	addDoc,
	getDocs,
	query,
	orderBy,
	doc,
	deleteDoc,
	updateDoc,
	getDoc,
} from 'firebase/firestore';
import { db } from '../firebase-config';
import '../styles/comment.css';

const CommentSection = () => {
	const user = useSelector((state) => state.auth.user);
	const language = useSelector((state) => state.language.language); // Get current language
	const [comments, setComments] = useState([]);
	const [newComment, setNewComment] = useState('');
	const [editingComment, setEditingComment] = useState(null);
	const [updatedCommentText, setUpdatedCommentText] = useState('');
	const [error, setError] = useState('');
	const commentCooldown = 15 * 60 * 1000; // 15 minutes in milliseconds

	// Fetch comments from Firebase
	const fetchComments = async () => {
		try {
			const q = query(
				collection(db, 'comments'),
				orderBy('createdAt', 'desc')
			);
			const querySnapshot = await getDocs(q);
			const commentsData = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			setComments(commentsData);
		} catch (error) {
			console.error('Error receiving comments:', error);
		}
	};

	const handleCommentSubmit = async (e) => {
		e.preventDefault();
		setError('');

		if (!user) {
			setError(
				language === 'EN'
					? 'Please login to comment.'
					: 'Lütfen giriş yapın.'
			);
			return;
		}

		const userDocRef = doc(db, 'users', user.uid);
		const userDoc = await getDoc(userDocRef);

		let lastCommentTime = null;
		if (userDoc.exists()) {
			lastCommentTime = userDoc.data().lastCommentTime?.toDate(); // Convert Firestore timestamp to Date
		}

		const now = new Date();
		if (lastCommentTime && now - lastCommentTime < commentCooldown) {
			const remainingTime = calculateRemainingTime(lastCommentTime);
			setError(
				language === 'EN'
					? `Wait for ${remainingTime} to be able to comment.`
					: `Yorum yapabilmek için ${remainingTime} bekleyin.`
			);
			return;
		}

		if (newComment.trim()) {
			try {
				await addDoc(collection(db, 'comments'), {
					text: newComment,
					userId: user.uid,
					userName: user.displayName || 'Anonim',
					createdAt: now,
				});
				await updateDoc(userDocRef, { lastCommentTime: now }); // Update last comment time
				setNewComment('');
				fetchComments();
			} catch (error) {
				setError(
					language === 'EN'
						? 'There was an error submitting a comment. Please try again.'
						: 'Yorum gönderirken bir hata oluştu. Lütfen tekrar deneyin.'
				);
			}
		} else {
			setError(
				language === 'EN'
					? 'Comment cannot be empty.'
					: 'Yorum boş olamaz.'
			);
		}
	};

	// Calculate remaining time
	const calculateRemainingTime = (lastCommentTime) => {
		const now = new Date();
		const timeDifference = commentCooldown - (now - lastCommentTime);
		if (timeDifference <= 0) return null; // Cooldown period expired

		const minutesLeft = Math.floor((timeDifference / 1000 / 60) % 60);
		const secondsLeft = Math.floor((timeDifference / 1000) % 60);
		return `${minutesLeft} ${
			language === 'EN' ? 'minutes' : 'dakika'
		} ${secondsLeft} ${language === 'EN' ? 'second' : 'saniye'}`;
	};

	useEffect(() => {
		fetchComments();
	}, []);

	// Delete a comment
	const handleDeleteComment = async (id) => {
		await deleteDoc(doc(db, 'comments', id));
		fetchComments();
	};

	// Edit a comment
	const handleEditComment = async (id, updatedText) => {
		await updateDoc(doc(db, 'comments', id), {
			text: updatedText,
		});
		setEditingComment(null);
		fetchComments();
	};

	// Open edit form
	const openEditForm = (comment) => {
		setEditingComment(comment.id);
		setUpdatedCommentText(comment.text);
	};

	// Cancel edit
	const cancelEdit = () => {
		setEditingComment(null);
		setUpdatedCommentText('');
	};

	return (
		<div className='comment-section'>
			<h2 className='comment-header-text'>UlkePuan Blog</h2>
			{error && <p style={{ color: 'red' }}>{error}</p>}
			<form onSubmit={handleCommentSubmit}>
				<textarea
					className='comment-value'
					value={newComment}
					onChange={(e) => setNewComment(e.target.value)}
					placeholder={
						language === 'EN'
							? 'Start typing your comment here...'
							: 'Yorumunuzu buraya yazın...'
					}
					rows='4'
				></textarea>
				<button className='send-button' type='submit'>
					{language === 'EN' ? 'Send' : 'Gönder'}
				</button>
			</form>
			<div
				className='comments-list'
				style={{
					maxHeight: '400px',
					overflowY: 'scroll',
				}}
			>
				{comments.map((comment) => (
					<div key={comment.id} className='comment'>
						{editingComment === comment.id ? (
							<div>
								<textarea
									value={updatedCommentText}
									onChange={(e) =>
										setUpdatedCommentText(e.target.value)
									}
									rows='4'
									className='comment-value-edit'
								/>
								<button
									className='edit-button'
									onClick={() =>
										handleEditComment(
											comment.id,
											updatedCommentText
										)
									}
								>
									{language === 'EN' ? 'Update' : 'Güncelle'}
								</button>
								<button
									className='delete-button'
									onClick={cancelEdit}
								>
									{language === 'EN' ? 'Cancel' : 'İptal'}
								</button>
							</div>
						) : (
							<div>
								<p className='comment-text'>
									<strong>{comment.userName}: </strong>
									{comment.text}
								</p>
								<p
									style={{
										fontSize: '0.8rem',
										color: '#888',
										marginLeft: '10px',
									}}
								>
									{new Date(
										comment.createdAt.seconds * 1000
									).toLocaleString()}
								</p>
								{user?.uid === comment.userId && (
									<div>
										<button
											className='edit-button'
											onClick={() =>
												openEditForm(comment)
											}
										>
											{language === 'EN'
												? 'Edit'
												: 'Düzenle'}
										</button>
										<button
											className='delete-button'
											onClick={() =>
												handleDeleteComment(comment.id)
											}
										>
											{language === 'EN'
												? 'Delete'
												: 'Sil'}
										</button>
									</div>
								)}
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default CommentSection;
