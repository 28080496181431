import React from 'react';
import { useSelector } from 'react-redux';
import translations from '../context/translations';
import '../styles/modal.css';

const HowToPlayModal = ({ isOpen, onClose }) => {
	const { language } = useSelector((state) => state.language);

	// Fetch the correct translation based on the selected language
	const t = translations[language];

	if (!isOpen) return null;

	return (
		<div className='modal-overlay'>
			<div className='modal-content'>
				<h2>{t.title}</h2>
				<div className='modal-body'>
					<p>
						<strong>{t.purpose}</strong>
					</p>
					<hr />
					<h3>{t.rulesTitle}</h3>
					<p>{t.leagueSelection}</p>
					<p>{t.teamSelection}</p>
					<hr />
					<h3>{t.sendUpdate}</h3>
					<p>{t.sendExplanation}</p>
					<p>{t.updateExplanation}</p>
					<hr />
					<h3>{t.dataHandling}</h3>
					<p>{t.dataSwitchingExplanation}</p>
					<p>{t.dataProtection}</p>
					<hr />
					<h3>{t.dragDropTitle}</h3>
					<p>{t.dragDropExplanation}</p>
					<hr />
					<h3>{t.errorsAndWarnings}</h3>
					<p>{t.errorsExplanation}</p>
					<p>{t.noChangeWarning}</p>
					<hr />
					<h3>{t.clearFunction}</h3>
					<p>{t.clearExplanation}</p>
					<hr />
					<h3>{t.gameTimeAndPrizes}</h3>
					<p>{t.gameTimeExplanation}</p>
					<p>{t.prizesExplanation}</p>
					<hr />
					<h3>{t.strategyTitle}</h3>
					<p>{t.strategyExplanation}</p>

					{/* Yeni Puanlama ve Kurallar Bölümü */}
					<hr />
					<h3>{t.puanlamaTitle}</h3>
					<p>{t.puanlamaExplanation}</p>
					<p>
						<strong>{t.puanlamaExampleTitle}</strong>{' '}
						{t.puanlamaExample}
					</p>
					<hr />
					<h3>{t.rules2Title}</h3>
					<p>{t.rules2Explanation}</p>
				</div>

				<button onClick={onClose} className='close-button'>
					{t.closeButton}
				</button>
			</div>
		</div>
	);
};

export default HowToPlayModal;
