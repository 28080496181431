import React, { useState, useEffect } from 'react';
import data from '../data/points.json';
import '../styles/table.css'; // CSS dosyasını içeri aktarıyoruz
import tableLanguage from '../context/tableLanguage';
import { useSelector } from 'react-redux';
const TeamPointsTable = () => {
	const [selectedSeason, setSelectedSeason] = useState(
		data.seasons[0].season
	);
	const [teams, setTeams] = useState([]);
	const [hasCountryPart, setHasCountryPart] = useState(false);
	const [hasTeamCoef, setHasTeamCoef] = useState(false);
	const [hasMatches, setHasMatches] = useState(false);
	const { language } = useSelector((state) => state.language);

	useEffect(() => {
		const season = data.seasons.find((s) => s.season === selectedSeason);
		if (season) {
			setTeams(season.teams);
			setHasCountryPart(
				season.teams.some((team) => team.hasOwnProperty('CountryPart'))
			);
			setHasTeamCoef(
				season.teams.some((team) => team.hasOwnProperty('TeamCoef'))
			);
			setHasMatches(
				season.teams.some((team) => team.hasOwnProperty('Matches'))
			);
		}
	}, [selectedSeason]);

	const seasonNames = data.seasons.map((season) => season.season);

	// Language texts
	const texts = tableLanguage[language];

	return (
		<div className='table-container'>
			<h2 className='table-title'>{texts.countryScoreTitle}</h2>
			<select
				onChange={(e) => setSelectedSeason(e.target.value)}
				value={selectedSeason}
				className='season-select'
			>
				{seasonNames.map((season) => (
					<option key={season} value={season}>
						{season}
					</option>
				))}
			</select>
			<div className='table-wrapper'>
				<table className='table'>
					<thead>
						<tr>
							<th>{texts.teamName}</th>
							<th>{texts.country}</th>
							<th>{texts.cup}</th>
							{hasMatches && <th>{texts.matchesCount}</th>}
							<th>{texts.matchPoints}</th>
							<th>{texts.qualifyingPoints}</th>
							<th>{texts.bonusPoints}</th>
							<th>{texts.totalPoints}</th>
							{hasCountryPart && <th>{texts.countryPart}</th>}
							{hasTeamCoef && <th>{texts.teamCoef}</th>}
						</tr>
					</thead>
					<tbody>
						{teams.slice(0, 10).map((team, index) => (
							<tr key={index}>
								<td>{team.teamName}</td>
								<td>{team.country}</td>
								<td>{team.cup}</td>
								{hasMatches && <td>{team.Matches || '-'}</td>}
								<td>{team.MatchPoints}</td>
								<td>{team.QualiPoints}</td>
								<td>{team.BonusPoints}</td>
								<td>{team.TotalPoints}</td>
								{hasCountryPart && (
									<td>{team.CountryPart || '-'}</td>
								)}
								{hasTeamCoef && <td>{team.TeamCoef || '-'}</td>}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TeamPointsTable;
