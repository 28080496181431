import React, { useEffect } from 'react';
import '../styles/header.css'; // Stil dosyasını daha sonra ekleyeceğiz
import { useNavigate } from 'react-router-dom';
import { changeLanguage } from '../store/languageSlice'; // Redux action to change language
import { useDispatch, useSelector } from 'react-redux';

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const logo =
		'https://pbs.twimg.com/profile_images/1563266428089671681/7ZH-1Yzz_400x400.jpg';
	const { language } = useSelector((state) => state.language);

	const mainPage = () => {
		navigate('/');
	};
	// localStorage'dan dil tercihini yükleme
	useEffect(() => {
		const savedLanguage = localStorage.getItem('selectedLanguage');
		if (savedLanguage) {
			dispatch(changeLanguage(savedLanguage));
		}
	}, [dispatch]);
	const handleLanguageChange = () => {
		const newLanguage = language === 'EN' ? 'TR' : 'EN'; // Toggle language
		dispatch(changeLanguage(newLanguage));
		localStorage.setItem('selectedLanguage', newLanguage); // Save the language preference
	};

	return (
		<>
			<img
				src={logo}
				alt='logo'
				className='dashboard-logo'
				onClick={mainPage}
			/>
			<button onClick={handleLanguageChange} className='dashboard-button'>
				{language}
			</button>
		</>
	);
};

export default Header;
