import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore'; // onSnapshot kullanılıyor
import { firestore } from '../firebase-config'; // Firestore bağlantısı
import '../styles/rank.css'; // Stil dosyası, HomeScreen tasarımıyla uyumlu olacak
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Assuming language is in Redux state

const RankScreen = () => {
	const [userRanks, setUserRanks] = useState([]);
	const [currentLeague, setCurrentLeague] = useState('ucl'); // Başlangıçta UCL ligi seçili
	const [loading, setLoading] = useState(true); // Yüklenme durumunu kontrol etmek için
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const currentUserId = user ? user.uid : null;

	// Get the selected language from Redux
	const { language } = useSelector((state) => state.language);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Track window width

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// Text translations for both languages
	const texts = {
		EN: {
			header: 'User Rankings',
			league: 'League',
			position: 'NO',
			user: 'User',
			points: 'Points',
			back: 'Go Back',
			loading: 'Loading...',
		},
		TR: {
			header: 'Kullanıcı Sıralamaları',
			league: 'Ligi',
			position: 'Sıra',
			user: 'Kullanıcı',
			points: 'Puanı',
			back: 'Geri Dön',
			loading: 'Yükleniyor...',
		},
	};

	const t = texts[language]; // Shorter reference to the translations

	// Kullanıcıları Firestore'dan alıp sıralama fonksiyonu
	useEffect(() => {
		const usersCollectionRef = collection(firestore, 'users');

		// Real-time updates için onSnapshot kullanılıyor
		const unsubscribe = onSnapshot(usersCollectionRef, (snapshot) => {
			const users = [];

			// Firestore'daki her kullanıcıyı diziye ekle
			snapshot.forEach((doc) => {
				const userData = doc.data();
				const weeklyResults = userData.weeklyResults || {}; // Eğer weeklyResults tanımlı değilse boş bir obje olarak al

				users.push({
					id: doc.id,
					displayName: userData.displayName || 'Anonim',
					uclScore: weeklyResults.uclScore || 0, // Eğer uclScore tanımlı değilse 0 olarak ayarla
					uelScore: weeklyResults.uelScore || 0, // Eğer uelScore tanımlı değilse 0 olarak ayarla
					ueclScore: weeklyResults.ueclScore || 0, // Eğer ueclScore tanımlı değilse 0 olarak ayarla
				});
			});

			// Seçilen lige göre kullanıcıları sıralıyoruz
			if (currentLeague === 'ucl') {
				users.sort((a, b) => b.uclScore - a.uclScore);
			} else if (currentLeague === 'uel') {
				users.sort((a, b) => b.uelScore - a.uelScore);
			} else if (currentLeague === 'uecl') {
				users.sort((a, b) => b.ueclScore - a.ueclScore);
			}

			// Kullanıcılara sıralama numarası ekle
			const rankedUsers = users.map((user, index) => ({
				...user,
				rank: index + 1, // Sıralama 1'den başlıyor
			}));

			setUserRanks(rankedUsers);
			setLoading(false); // Yüklenme tamamlandı
		});

		// Cleanup function: veritabanı bağlantısını kapat
		return () => unsubscribe();
	}, [currentLeague]);

	const goBack = () => {
		navigate('/rank-king');
	};

	return (
		<div
			className={`rank-main ${
				loading ? 'rank-loading-background' : currentLeague
			}`}
		>
			<div className='rank-main-container'>
				<div className='rank-content'>
					<header className='rank-header-container'>
						<h1
							className='rank-header-title'
							style={{
								color:
									currentLeague === 'ucl'
										? '#1a58ff'
										: currentLeague === 'uel'
										? '#f05716'
										: '#00bc12',
							}}
						>
							RanK<span className='rank-reverse-k'>K</span>ing
						</h1>
						<p className='rank-header-subtitle'>{t.header}</p>
					</header>

					<div className='rank-league-selection'>
						<h2
							className={`rank-league-title ${
								currentLeague === 'ucl' ? 'active ucl' : ''
							}`}
							onClick={() => setCurrentLeague('ucl')}
						>
							{currentLeague === 'ucl' ? `UCL` : `UCL`}
						</h2>
						<h2
							className={`rank-league-title ${
								currentLeague === 'uel' ? 'active uel' : ''
							}`}
							onClick={() => setCurrentLeague('uel')}
						>
							{currentLeague === 'ucl' ? `UEL` : `UEL`}
						</h2>
						<h2
							className={`rank-league-title ${
								currentLeague === 'uecl' ? 'active uecl' : ''
							}`}
							onClick={() => setCurrentLeague('uecl')}
						>
							{currentLeague === 'ucl' ? `UECL` : `UECL`}
						</h2>
					</div>

					{loading ? (
						<div className='rank-loading-background'>
							<p className='rank-loading-text'>{t.loading}</p>
						</div>
					) : (
						<table className='rank-table'>
							<thead>
								<tr>
									<th className='classno'>{t.position}</th>
									<th>{t.user}</th>
									<th>{t.points}</th>
								</tr>
							</thead>
							<tbody>
								{userRanks.map((user) => (
									<tr
										key={user.id}
										className={
											user.id === currentUserId
												? 'highlighted-row'
												: ''
										}
									>
										<td className='classno rank-number'>
											{user.rank}
										</td>
										<td className='user'>
											{windowWidth < 400 &&
											user.displayName.length > 11
												? `${user.displayName.slice(
														0,
														10
												  )}...`
												: user.displayName}
										</td>
										<td className='points'>
											{currentLeague === 'ucl' &&
												user.uclScore}
											{currentLeague === 'uel' &&
												user.uelScore}
											{currentLeague === 'uecl' &&
												user.ueclScore}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
				<button
					onClick={goBack}
					className={`goback-button ${currentLeague}-goback-button`}
				>
					<FiArrowLeft size={44} color='white' />
				</button>
			</div>
		</div>
	);
};

export default RankScreen;
