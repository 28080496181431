import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../firebase-config'; // Add firebase-config for database
import {
	TwitterAuthProvider,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail, // Firebase şifre sıfırlama fonksiyonu
} from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../store/authSlice';
import { ref, set, get } from 'firebase/database'; // Import Firebase Realtime Database
import '../styles/login.css';

const LoginScreen = () => {
	const logo =
		'https://pbs.twimg.com/profile_images/1563266428089671681/7ZH-1Yzz_400x400.jpg';
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Redux'tan language bilgisini alıyoruz
	const { language } = useSelector((state) => state.language);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [username, setUsername] = useState(''); // State for username
	const [error, setError] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null); // Şifre sıfırlama başarılı mesajı
	const [isRegistering, setIsRegistering] = useState(false); // Track if the user is registering

	// Dil desteği için metinleri oluşturuyoruz
	const translations = {
		EN: {
			loginTitle: 'Log in to Ulke Puani',
			registerTitle: 'Sign up for Ulke Puani',
			signInWithEmail: 'Sign in with Email',
			signUpWithEmail: 'Sign up with Email',
			signInWithTwitter: 'Sign in with Twitter',
			alreadyHaveAccount: 'Already have an account? Log in',
			dontHaveAccount: "Don't have an account? Sign up",
			forgotPassword: 'Forgot Password',
			support: 'Support',
		},
		TR: {
			loginTitle: "Ulke Puani'na Giriş Yap",
			registerTitle: "Ulke Puani'na Kaydol",
			signInWithEmail: 'E-posta ile Giriş Yap',
			signUpWithEmail: 'E-posta ile Kaydol',
			signInWithTwitter: 'Twitter ile Giriş Yap',
			alreadyHaveAccount: 'Zaten bir hesabınız var mı? Giriş Yap',
			dontHaveAccount: 'Hesabınız yok mu? Kaydol',
			forgotPassword: 'Şifremi Unuttum',
			support: 'Destek',
		},
	};

	// Seçilen dile göre metinleri alıyoruz
	const t = translations[language];

	// Eğer kullanıcı oturum açmışsa, doğrudan HomeScreen'e yönlendirin
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				navigate('/');
			}
		});

		return () => unsubscribe();
	}, [navigate]);

	const signInWithTwitter = () => {
		const provider = new TwitterAuthProvider();
		signInWithPopup(auth, provider)
			.then((result) => {
				dispatch(loginSuccess(result.user));
				navigate('/');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleEmailLogin = (e) => {
		e.preventDefault();
		setSuccessMessage(null); // Başarı mesajını temizle

		if (isRegistering) {
			// Registering new user and storing username
			if (username) {
				const usernameRef = ref(database, 'users');
				get(usernameRef)
					.then((snapshot) => {
						if (snapshot.exists()) {
							const usersData = snapshot.val();
							const usernames = Object.values(usersData).map(
								(user) => user.username
							);
							if (usernames.includes(username)) {
								setError('Bu kullanıcı adı zaten kullanımda.');
								return;
							} else {
								createUserWithEmailAndPassword(
									auth,
									email,
									password
								)
									.then((result) => {
										const user = result.user;
										set(
											ref(database, `users/${user.uid}`),
											{
												username: username,
												email: user.email,
											}
										);
										dispatch(
											loginSuccess({
												...user,
												displayName: username,
											})
										);
										navigate('/');
									})
									.catch((err) => {
										const errorMessage = getErrorMessage(
											err.code
										);
										setError(errorMessage);
									});
							}
						} else {
							createUserWithEmailAndPassword(
								auth,
								email,
								password
							)
								.then((result) => {
									const user = result.user;
									set(ref(database, `users/${user.uid}`), {
										username: username,
										email: user.email,
									});
									dispatch(
										loginSuccess({
											...user,
											displayName: username,
										})
									);
									navigate('/');
								})
								.catch((err) => {
									const errorMessage = getErrorMessage(
										err.code
									);
									setError(errorMessage);
								});
						}
					})
					.catch((err) => {
						console.error(
							'Error checking username availability:',
							err
						);
						setError(
							'Kullanıcı adı kontrol edilirken bir hata oluştu. Lütfen tekrar deneyin.'
						);
					});
			} else {
				setError('Lütfen bir kullanıcı adı girin.');
			}
		} else {
			signInWithEmailAndPassword(auth, email, password)
				.then((result) => {
					const user = result.user;
					const userRef = ref(database, `users/${user.uid}`);
					get(userRef)
						.then((snapshot) => {
							if (snapshot.exists()) {
								const userData = snapshot.val();
								const displayName = userData.username;
								dispatch(
									loginSuccess({
										...user,
										displayName: displayName,
									})
								);
								navigate('/');
							} else {
								console.error('No user data found');
								setError('Kullanıcı bilgileri bulunamadı.');
							}
						})
						.catch((err) => {
							console.error('Error fetching user data:', err);
							setError(
								'Kullanıcı verileri alınırken bir hata oluştu.'
							);
						});
				})
				.catch((err) => {
					const errorMessage = getErrorMessage(err.code);
					setError(errorMessage);
				});
		}
	};

	const handlePasswordReset = () => {
		setSuccessMessage(null);
		setError(null);

		if (email) {
			const userRef = ref(database, `passwordResets/${btoa(email)}`);
			get(userRef)
				.then((snapshot) => {
					const currentTime = Date.now();
					const sixtyMinutesInMillis = 60 * 60 * 1000;

					if (snapshot.exists()) {
						const lastResetTime = snapshot.val().timestamp;

						if (
							currentTime - lastResetTime <
							sixtyMinutesInMillis
						) {
							setError(
								'Şifre sıfırlama isteğini yalnızca 60 dakikada bir gönderebilirsiniz.'
							);
							return;
						}
					}

					sendPasswordResetEmail(auth, email)
						.then(() => {
							set(
								ref(database, `passwordResets/${btoa(email)}`),
								{
									timestamp: currentTime,
								}
							);
							setSuccessMessage(
								'Şifre sıfırlama bağlantısı e-posta adresinize gönderildi.'
							);
						})
						.catch((err) => {
							const errorMessage = getErrorMessage(err.code);
							setError(errorMessage);
						});
				})
				.catch((err) => {
					console.error('Error checking password reset time:', err);
					setError('Bir hata oluştu. Lütfen tekrar deneyin.');
				});
		} else {
			setError('Lütfen e-posta adresinizi girin.');
		}
	};

	useEffect(() => {
		let timer;
		if (error || successMessage) {
			timer = setTimeout(() => {
				setError(null);
				setSuccessMessage(null);
			}, 10000);
		}

		return () => clearTimeout(timer);
	}, [error, successMessage]);

	const toggleRegistering = () => {
		setIsRegistering(!isRegistering);
	};

	const goTwitter = () => {
		window.open('https://x.com/UlkePuan', '_blank');
	};
	const goInstagram = () => {
		window.open('https://www.instagram.com/ulkepuan/', '_blank');
	};
	const goYoutube = () => {
		window.open('https://www.youtube.com/@ulkepuan', '_blank');
	};

	const getErrorMessage = (errorCode) => {
		switch (errorCode) {
			case 'auth/invalid-email':
				return 'Geçersiz e-posta adresi. Lütfen e-posta adresinizi kontrol edin.';
			case 'auth/user-disabled':
				return 'Bu hesap devre dışı bırakılmış. Yardım için lütfen destek ile iletişime geçin.';
			case 'auth/user-not-found':
				return 'Bu e-posta ile bir kullanıcı bulunamadı. Lütfen kayıt olun ya da bilgilerinizi kontrol edin.';
			case 'auth/wrong-password':
				return 'Hatalı şifre girdiniz. Lütfen şifrenizi kontrol edin.';
			case 'auth/email-already-in-use':
				return 'Bu e-posta adresi zaten kullanımda. Farklı bir e-posta ile giriş yapmayı deneyin.';
			case 'auth/weak-password':
				return 'Güvenliğiniz için daha güçlü bir şifre belirleyin (en az 6 karakter).';
			case 'auth/invalid-credential':
				return 'Geçersiz kimlik bilgileri. Lütfen giriş bilgilerinizi kontrol edin.';
			case 'auth/too-many-requests':
				return 'Çok fazla giriş denemesi yaptınız. Lütfen daha sonra tekrar deneyin.';
			case 'auth/network-request-failed':
				return 'Bağlantı sorunu. Lütfen internet bağlantınızı kontrol edin.';
			default:
				return 'Bir hata oluştu. Lütfen tekrar deneyin.';
		}
	};

	return (
		<div className='login-main'>
			<div className='login-screen'>
				<div className='login-section'>
					<h1 className='login-title'>
						{isRegistering ? t.registerTitle : t.loginTitle}
					</h1>
					<div className='login-form'>
						<form
							onSubmit={handleEmailLogin}
							className='email-login-form'
						>
							{isRegistering && (
								<input
									type='text'
									className='input'
									placeholder='Username'
									value={username}
									onChange={(e) =>
										setUsername(e.target.value)
									}
									required
								/>
							)}
							<input
								type='email'
								className='input'
								placeholder='Email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
							<input
								className='input'
								type='password'
								placeholder='Password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
							<button type='submit'>
								{isRegistering
									? t.signUpWithEmail
									: t.signInWithEmail}
							</button>
						</form>
						<button
							onClick={signInWithTwitter}
							className='twitter-login-button'
						>
							{t.signInWithTwitter}
						</button>
						<p
							onClick={toggleRegistering}
							className='toggle-login-register'
						>
							{isRegistering
								? t.alreadyHaveAccount
								: t.dontHaveAccount}
						</p>
						<p
							onClick={handlePasswordReset}
							className='forgot-password'
						>
							{t.forgotPassword}
						</p>
						{error && (
							<p className='error-message-login'>{error}</p>
						)}
						{successMessage && (
							<p className='success-message-login'>
								{successMessage}
							</p>
						)}
					</div>
				</div>
			</div>
			{/* 			<div className='login-footer'>
				<a
					href='mailto:destek@ulkepuan.net'
					className='login-footer-text'
				>
					{t.support}
				</a>
				<p className='login-footer-text' onClick={goTwitter}>
					Twitter
				</p>
				<p className='login-footer-text' onClick={goInstagram}>
					Instagram
				</p>
				<p className='login-footer-text' onClick={goYoutube}>
					Youtube
				</p>
			</div> */}
		</div>
	);
};

export default LoginScreen;
