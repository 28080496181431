import React, { useEffect } from 'react';

const Page = ({ tweetId }) => {
	useEffect(() => {
		// Twitter widget script'ini yükleyin
		const script = document.createElement('script');
		script.src = 'https://platform.twitter.com/widgets.js';
		script.async = true;
		document.body.appendChild(script);

		// Temizleme işlemi
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<blockquote className='twitter-tweet'>
			<p lang='tur' dir='ltr'>
				Just setting up my twitter
			</p>
			—UlkePuan (@ulkepuan){' '}
			<a href={`https://twitter.com/jack/status/${tweetId}`}>2024</a>
		</blockquote>
	);
};

export default Page;
