// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import teamsReducer from './teamSlice';
import authReducer from './authSlice';
import languageReducer from './languageSlice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		teams: teamsReducer,
		language: languageReducer,
	},
});
