import React, { useState, useEffect } from 'react';
import {
	VictoryBar,
	VictoryChart,
	VictoryAxis,
	VictoryTheme,
	VictoryTooltip,
	VictoryLabel,
} from 'victory';
import data from '../data/points.json';
import { useSelector } from 'react-redux';
import chartLanguage from '../context/chartLanguage'; // Import your language data

const TeamPointsChart = () => {
	const [topTeams, setTopTeams] = useState([]);
	const { language } = useSelector((state) => state.language);

	useEffect(() => {
		prepareChartData();
	}, []);

	const prepareChartData = () => {
		if (!data || !data.seasons || data.seasons.length === 0) {
			console.error('Data veya seasons bulunamadı veya boş.');
			return;
		}

		// Aggregate total points for each team across all seasons
		const teamPoints = {};

		data.seasons.forEach((season) => {
			season.teams.forEach((team) => {
				if (!teamPoints[team.teamName]) {
					teamPoints[team.teamName] = 0;
				}
				teamPoints[team.teamName] += parseFloat(team.TotalPoints);
			});
		});

		// Sort teams by total points and take the top 10
		const sortedTeams = Object.entries(teamPoints)
			.map(([teamName, points]) => ({ x: teamName, y: points }))
			.sort((a, b) => b.y - a.y)
			.slice(0, 10);

		setTopTeams(sortedTeams);
	};

	// Get the appropriate texts based on the selected language
	const texts = chartLanguage[language];

	return (
		<div style={{ width: '100%', margin: 'auto', maxWidth: 700 }}>
			<h2>
				{texts.topTeamsTitle ||
					'Son 20 Yılda En Çok Puan Toplayan 10 Takım'}
			</h2>
			<VictoryChart
				theme={VictoryTheme.material}
				domainPadding={{ x: 50 }}
				padding={{ top: 20, bottom: 100, left: 60, right: 40 }}
			>
				<VictoryAxis
					tickLabelComponent={
						<VictoryLabel angle={90} textAnchor='start' />
					}
					style={{
						tickLabels: {
							fontSize: 12, // Increased font size
							padding: 5,
							fill: '#cccccc', // Change this to your desired color
						},
					}}
				/>
				<VictoryAxis
					dependentAxis
					style={{
						tickLabels: {
							fontSize: 12, // Increased font size
							fill: '#cccccc', // Change this to your desired color
						},
					}}
				/>
				<VictoryBar
					data={topTeams}
					labels={({ datum }) => `${datum.y}`}
					style={{ data: { fill: 'red' } }}
					labelComponent={<VictoryTooltip />}
				/>
			</VictoryChart>
		</div>
	);
};

export default TeamPointsChart;
