// src/redux/teamsSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const initialState = {
	selectedUCLTeams: [],
	selectedUELTeams: [],
	selectedUECLTeams: [],
};

export const teamsSlice = createSlice({
	name: 'teams',
	initialState,
	reducers: {
		// Set all selected teams safely
		setSelectedTeams: (state, action) => {
			state.selectedUCLTeams = action.payload.selectedUCLTeams || [];
			state.selectedUELTeams = action.payload.selectedUELTeams || [];
			state.selectedUECLTeams = action.payload.selectedUECLTeams || [];
		},
		// Select UCL team safely
		selectUCLTeam: (state, action) => {
			state.selectedUCLTeams = state.selectedUCLTeams || []; // Ensure array exists
			if (!state.selectedUCLTeams.includes(action.payload)) {
				state.selectedUCLTeams.push(action.payload);
			}
		},
		// Deselect UCL team safely
		deselectUCLTeam: (state, action) => {
			state.selectedUCLTeams = state.selectedUCLTeams || []; // Ensure array exists
			state.selectedUCLTeams = state.selectedUCLTeams.filter(
				(team) => team !== action.payload
			);
		},
		// Select UEL team safely
		selectUELTeam: (state, action) => {
			state.selectedUELTeams = state.selectedUELTeams || []; // Ensure array exists
			if (!state.selectedUELTeams.includes(action.payload)) {
				state.selectedUELTeams.push(action.payload);
			}
		},
		// Deselect UEL team safely
		deselectUELTeam: (state, action) => {
			state.selectedUELTeams = state.selectedUELTeams || []; // Ensure array exists
			state.selectedUELTeams = state.selectedUELTeams.filter(
				(team) => team !== action.payload
			);
		},
		// Select UECL team safely
		selectUECLTeam: (state, action) => {
			state.selectedUECLTeams = state.selectedUECLTeams || []; // Ensure array exists
			if (!state.selectedUECLTeams.includes(action.payload)) {
				state.selectedUECLTeams.push(action.payload);
			}
		},
		// Deselect UECL team safely
		deselectUECLTeam: (state, action) => {
			state.selectedUECLTeams = state.selectedUECLTeams || []; // Ensure array exists
			state.selectedUECLTeams = state.selectedUECLTeams.filter(
				(team) => team !== action.payload
			);
		},
		// Clear UCL teams
		clearUCLTeams: (state) => {
			state.selectedUCLTeams = [];
		},
		// Clear UEL teams
		clearUELTeams: (state) => {
			state.selectedUELTeams = [];
		},
		// Clear UECL teams
		clearUECLTeams: (state) => {
			state.selectedUECLTeams = [];
		},
	},
});

// Exporting all actions
export const {
	setSelectedTeams,
	selectUCLTeam,
	deselectUCLTeam,
	selectUELTeam,
	deselectUELTeam,
	selectUECLTeam,
	deselectUECLTeam,
	clearUCLTeams,
	clearUELTeams,
	clearUECLTeams,
} = teamsSlice.actions;

// Clear only the teams of the selected league
export const clearSelectedLeagueTeams = (league) => {
	return (dispatch) => {
		switch (league) {
			case 'ucl':
				dispatch(clearUCLTeams());
				break;
			case 'uel':
				dispatch(clearUELTeams());
				break;
			case 'uecl':
				dispatch(clearUECLTeams());
				break;
			default:
				break;
		}
	};
};

export default teamsSlice.reducer;
