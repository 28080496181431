import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebase-config'; // Firebase ayarlarınız
import '../styles/resetpassword.css'; // Şifre sıfırlama için yeni CSS dosyası

const ResetPasswordScreen = () => {
	const [searchParams] = useSearchParams(); // URL'deki parametreleri almak için kullanıyoruz
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState(''); // Şifre tekrar
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const [validCode, setValidCode] = useState(false); // Kod geçerli mi kontrolü
	const navigate = useNavigate();

	// URL'den oobCode ve mode'u alıyoruz
	const oobCode = searchParams.get('oobCode');
	const mode = searchParams.get('mode');

	useEffect(() => {
		if (oobCode && mode === 'resetPassword') {
			// Firebase şifre sıfırlama kodunu doğrula
			verifyPasswordResetCode(auth, oobCode)
				.then(() => {
					setValidCode(true); // Kod geçerli
				})
				.catch((err) => {
					setError(
						'Geçersiz veya süresi dolmuş bağlantı. Lütfen tekrar şifre sıfırlama isteği gönderin.'
					);
					setValidCode(false); // Kod geçersiz
				});
		} else {
			setError('Geçersiz işlem.');
		}
	}, [oobCode, mode]);

	const handlePasswordReset = async (e) => {
		e.preventDefault();

		// Şifre minimum 6 karakter olmalı kontrolü
		if (newPassword.length < 6) {
			setError('Şifre en az 6 karakter olmalıdır.');
			return;
		}

		// Şifrelerin eşleşip eşleşmediği kontrolü
		if (newPassword !== confirmPassword) {
			setError('Şifreler eşleşmiyor.');
			return;
		}

		// Eğer geçerli şifre sıfırlama kodu ve şifre varsa
		if (!oobCode || !validCode) {
			setError('Geçersiz işlem.');
			return;
		}

		try {
			await confirmPasswordReset(auth, oobCode, newPassword); // Firebase şifre sıfırlama fonksiyonu
			setSuccess(true);
			setTimeout(() => navigate('/'), 3000); // Başarı sonrası login sayfasına yönlendirme
		} catch (err) {
			// Firebase'den dönen hata mesajını göster
			setError(
				'Şifre sıfırlanırken bir hata oluştu. Lütfen tekrar deneyin.'
			);
		}
	};

	return (
		<div className='reset-password-main'>
			<div className='reset-password-screen'>
				<div className='reset-password-container'>
					<h1 className='reset-password-title'>
						Şifrenizi Sıfırlayın
					</h1>

					{error && <p className='error-message'>{error}</p>}
					{success ? (
						<p className='success-message'>
							Şifreniz başarıyla sıfırlandı. Giriş sayfasına
							yönlendiriliyorsunuz...
						</p>
					) : validCode ? (
						<form
							onSubmit={handlePasswordReset}
							className='reset-form'
						>
							<input
								type='password'
								placeholder='Yeni Şifre'
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								className='input'
								required
							/>
							<input
								type='password'
								placeholder='Yeni Şifreyi Tekrar Girin'
								value={confirmPassword}
								onChange={(e) =>
									setConfirmPassword(e.target.value)
								}
								className='input'
								required
							/>
							<button type='submit' className='reset-button'>
								Şifreyi Sıfırla
							</button>
						</form>
					) : (
						<p className='error-message'>
							Geçersiz doğrulama kodu. Lütfen e-posta bağlantınızı
							kontrol edin.
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default ResetPasswordScreen;
