// languageData.js
const tableLanguage = {
	TR: {
		countryScoreTitle: 'Takım Puanları Tablosu',
		teamName: 'Takım Adı',
		country: 'Ülke',
		cup: 'Kupa',
		matchesCount: 'Maç Sayısı',
		matchPoints: 'Maç Puanı',
		qualifyingPoints: 'Kalıp Puanı',
		bonusPoints: 'Bonus Puanı',
		totalPoints: 'Toplam Puan',
		countryPart: 'Ülke Payı',
		teamCoef: 'Takım Koef.',
	},
	EN: {
		countryScoreTitle: 'Team Points Table',
		teamName: 'Team Name',
		country: 'Country',
		cup: 'Cup',
		matchesCount: 'Matches Count',
		matchPoints: 'Match Points',
		qualifyingPoints: 'Qualifying Points',
		bonusPoints: 'Bonus Points',
		totalPoints: 'Total Points',
		countryPart: 'Country Part',
		teamCoef: 'Team Coef.',
	},
};

export default tableLanguage;
