import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import '../styles/grid.css';

// Seçili takımları ligin adına göre almak için yardımcı fonksiyon
const getSelectedTeams = (
	league,
	selectedUCLTeams,
	selectedUELTeams,
	selectedUECLTeams
) => {
	switch (league) {
		case 'ucl':
			return selectedUCLTeams || []; // UCL takımları undefined ise boş array
		case 'uel':
			return selectedUELTeams || []; // UEL takımları undefined ise boş array
		case 'uecl':
			return selectedUECLTeams || []; // UECL takımları undefined ise boş array
		default:
			return [];
	}
};

// Takımın seçilip seçilmediğine göre renk ayarlama
const getTextColor = (league, isSelected) => {
	switch (league) {
		case 'ucl':
			return isSelected ? '#063089' : '#1a58ff'; // Seçildiyse koyu mavi, değilse açık mavi
		case 'uel':
			return '#f05716'; // UEL için turuncu
		case 'uecl':
			return '#00bc12'; // UECL için yeşil
		default:
			return 'black';
	}
};

const TeamGrid = ({ teams, league, onTeamSelect }) => {
	// Redux'tan seçili takımları almak
	const { selectedUCLTeams, selectedUELTeams, selectedUECLTeams } =
		useSelector((state) => state.teams, shallowEqual);

	// Geçerli lig için seçili takımları almak
	const selectedTeams = getSelectedTeams(
		league,
		selectedUCLTeams,
		selectedUELTeams,
		selectedUECLTeams
	);

	return (
		<div className='grid-container'>
			{teams.map((team, index) => {
				// Takımın seçilip seçilmediğini kontrol et
				const isSelected = selectedTeams.includes(team);

				return (
					<div
						key={index}
						className={`grid-item ${isSelected ? 'selected' : ''}`}
						onClick={() => onTeamSelect(team)} // Takım seçimini handle etmek
						style={{
							color: getTextColor(league, isSelected), // Dinamik metin rengi
							backgroundColor: isSelected ? '#555' : '#222', // Dinamik arka plan rengi
						}}
					>
						{team} {/* Takım ismi */}
					</div>
				);
			})}
		</div>
	);
};

export default TeamGrid;
