const dataScreenLanguage = {
	TR: {
		countryScoreTitle: 'Ülke Skoru',
		countryScoreSubtitle:
			"UEFA katsayıları, kulüplerin beş yıllık bir dönem boyunca Avrupa Kupalarındaki performanslarına dayanmaktadır. Bu 5 yıl boyunca Şampiyonlar Ligi (CL), Avrupa Ligi (EL) ve Konferans Ligi'nin (CO) grup aşamasından itibaren maç sonuçları (galibiyet için 2 puan ve beraberlik için 1 puan) verilir. Ancak, eleme turu play-off'larında maç sonuçları ülke sıralaması için sayılır ancak kulüp sıralaması için sayılmaz.",
		moreInfoText: 'Daha fazla bilgi için lütfen ',
		linkText: 'buraya',
		footerText: 'tıklayın.',
	},
	EN: {
		countryScoreTitle: 'Country Score',
		countryScoreSubtitle:
			"UEFA coefficients are based on clubs' performances in European competitions over a five-year period. Match results (2 points for a win and 1 point for a draw) are awarded for the Champions League (CL), Europa League (EL), and Conference League (CO) from the group stage onwards during this 5-year period. However, match results in the play-off round are counted for the country ranking but not for the club ranking.",
		moreInfoText: 'For more information please visit ',
		linkText: 'here',
		footerText: '.',
	},
};

export default dataScreenLanguage;
