import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/home.css';
import TeamGrid from '../components/TeamGrid';
import data from '../data/index.json';
import { database } from '../firebase-config';
import { ref, get, update } from 'firebase/database';
import { useDispatch, useSelector } from 'react-redux';
import {
	setSelectedTeams,
	/* 	selectUCLTeam,
	deselectUCLTeam,
	selectUELTeam,
	deselectUELTeam, */
	selectUECLTeam,
	deselectUECLTeam,
	clearSelectedLeagueTeams,
} from '../store/teamSlice';
import { logout } from '../store/authSlice';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FiLogOut, FiLogIn } from 'react-icons/fi';
import HowToPlayModal from '../components/HowToPlayModal';
import { changeLanguage } from '../store/languageSlice'; // Redux action to change language
import { getAuth, signOut } from 'firebase/auth';
import { doc, onSnapshot, setDoc } from 'firebase/firestore'; // Firestore metodları
import { firestore } from '../firebase-config'; // Firestore bağlantısı
/* import { db } from '../firebase-config';
 */ import { useLocation } from 'react-router-dom'; // Import useLocation
import GoogleAd from '../components/GoogleAd';

const HomeScreen = () => {
	const location = useLocation(); // Get the current location (route)
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { selectedUCLTeams, selectedUELTeams, selectedUECLTeams } =
		useSelector((state) => state.teams);
	const { user } = useSelector((state) => state.auth);
	const [isSubmitted, setIsSubmitted] = useState({
		ucl: false,
		uel: false,
		uecl: false,
	});
	const [currentLeague, setCurrentLeague] = useState('ucl');
	const [errorMessage, setErrorMessage] = useState('');
	const [errorMessageType, setErrorMessageType] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { language } = useSelector((state) => state.language);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [userScores, setUserScores] = useState({
		uclScore: 0,
		uelScore: 0,
		ueclScore: 0,
	});
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		console.log('Route changed, re-rendering...');
		// Perform any additional logic needed after route change
	}, [location]);

	// localStorage'dan dil tercihini yükleme
	useEffect(() => {
		const savedLanguage = localStorage.getItem('selectedLanguage');
		if (savedLanguage) {
			dispatch(changeLanguage(savedLanguage));
		}
	}, [dispatch]);

	useEffect(() => {
		const auth = getAuth();
		const currentUser = auth.currentUser;
		setIsLoggedIn(!!currentUser); // Update the state based on login status
	}, [location]);

	const handleLoginClick = () => {
		navigate('/login');
	};

	const userRanks = () => {
		navigate('/ranks');
	};

	const handleLanguageChange = () => {
		const newLanguage = language === 'EN' ? 'TR' : 'EN'; // Toggle language
		dispatch(changeLanguage(newLanguage));
		localStorage.setItem('selectedLanguage', newLanguage); // Save the language preference
	};

	/* 	const getUserData = async (userId) => {
		const userRef = db.collection('users').doc(userId);
		const userDoc = await userRef.get();

		if (userDoc.exists) {
			const userData = userDoc.data();
			return userData; // Burada uclRank, uelRank, ueclRank gibi sıralama bilgilerini içeriyor olmalı
		} else {
			console.log('No user data found');
			return null;
		}
	}; */

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	useEffect(() => {
		if (errorMessage) {
			console.log('Error Type:', errorMessageType); // Debug the type
			const timer = setTimeout(() => {
				setErrorMessage('');
				setErrorMessageType('');
			}, 10000);

			return () => clearTimeout(timer);
		}
	}, [errorMessage, errorMessageType]);

	const getBackgroundClass = () => {
		switch (currentLeague) {
			case 'ucl':
				return 'ucl';
			case 'uel':
				return 'uel';
			case 'uecl':
				return 'uecl';
			default:
				return '';
		}
	};

	useEffect(() => {
		if (isModalOpen) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}
		return () => {
			document.body.classList.remove('no-scroll');
		};
	}, [isModalOpen]);

	useEffect(() => {
		if (user && currentLeague) {
			const userId = user.uid;
			const userTeamsRef = ref(database, `users/${userId}`);

			get(userTeamsRef)
				.then((snapshot) => {
					if (snapshot.exists()) {
						const userData = snapshot.val();
						dispatch(
							setSelectedTeams({
								selectedUCLTeams: userData.uclTeams || [],
								selectedUELTeams: userData.uelTeams || [],
								selectedUECLTeams: userData.ueclTeams || [],
							})
						);

						setIsSubmitted({
							ucl: userData.uclTeams?.length === 36,
							uel: userData.uelTeams?.length === 36,
							uecl: userData.ueclTeams?.length === 36,
						});
					} else {
						console.log('No data available');
					}
				})
				.catch((error) => {
					console.error(
						'Error fetching user data from Firebase:',
						error
					);
				});
		}
	}, [currentLeague, user, dispatch]);

	const handleLogout = () => {
		const auth = getAuth(); // Firebase auth instance
		signOut(auth)
			.then(() => {
				// Dispatching the clear actions for each league
				dispatch(clearSelectedLeagueTeams('ucl'));
				dispatch(clearSelectedLeagueTeams('uel'));
				dispatch(clearSelectedLeagueTeams('uecl'));

				// Also dispatch the logout action for clearing user state
				dispatch(logout());

				// Re-render the component after logout
				setIsLoggedIn(false);

				// Alert immediately after setting the message
				alert('Oturum başarıyla kapatıldı.');

				// Navigate to the login screen or home page
				navigate('/');
			})
			.catch((error) => {
				// Handle errors and set error message
				console.error('Error during sign out:', error);
				setErrorMessage(
					'Oturum kapatma işlemi sırasında bir hata oluştu.'
				);
				setErrorMessageType('error');

				// Optionally show an error alert
				alert('Oturum kapatma işlemi sırasında bir hata oluştu.');
			});
	};

	/* 	const handleTeamSelectUCL = (team) => {
		const teams = selectedUCLTeams || [];
		if (teams.includes(team)) {
			dispatch(deselectUCLTeam(team));
		} else {
			dispatch(selectUCLTeam(team));
		}
	}; */

	/* 	const handleTeamSelectUEL = (team) => {
		const teams = selectedUELTeams || [];
		if (teams.includes(team)) {
			dispatch(deselectUELTeam(team));
		} else {
			dispatch(selectUELTeam(team));
		}
	}; */

	const handleTeamSelectUECL = (team) => {
		const teams = selectedUECLTeams || [];
		if (teams.includes(team)) {
			dispatch(deselectUECLTeam(team));
		} else {
			dispatch(selectUECLTeam(team));
		}
	};

	const onDragEndUCL = (result) => {
		if (!result.destination) return;
		const reorderedTeams = Array.from(selectedUCLTeams);
		const [removed] = reorderedTeams.splice(result.source.index, 1);
		reorderedTeams.splice(result.destination.index, 0, removed);
		dispatch(setSelectedTeams({ selectedUCLTeams: reorderedTeams }));
	};

	const onDragEndUEL = (result) => {
		if (!result.destination) return;
		const reorderedTeams = Array.from(selectedUELTeams);
		const [removed] = reorderedTeams.splice(result.source.index, 1);
		reorderedTeams.splice(result.destination.index, 0, removed);
		dispatch(setSelectedTeams({ selectedUELTeams: reorderedTeams }));
	};

	const onDragEndUECL = (result) => {
		if (!result.destination) return;
		const reorderedTeams = Array.from(selectedUECLTeams);
		const [removed] = reorderedTeams.splice(result.source.index, 1);
		reorderedTeams.splice(result.destination.index, 0, removed);
		dispatch(setSelectedTeams({ selectedUECLTeams: reorderedTeams }));
	};

	const handleClearSelectedTeams = () => {
		dispatch(clearSelectedLeagueTeams(currentLeague));
		setIsSubmitted((prevState) => ({
			...prevState,
			[currentLeague]: false,
		}));
		setErrorMessage(getText('teamsCleared'));
		setErrorMessageType('success');
	};

	const handleUpdate = () => {
		if (!user) {
			setErrorMessage('Kullanıcı oturum açmamış.');
			setErrorMessageType('error');
			return;
		}

		let selectedTeams = [];
		switch (currentLeague) {
			case 'ucl':
				selectedTeams = selectedUCLTeams;
				break;
			case 'uel':
				selectedTeams = selectedUELTeams;
				break;
			case 'uecl':
				selectedTeams = selectedUECLTeams;
				break;
			default:
				return;
		}

		if (selectedTeams.length === 0 || selectedTeams.length !== 36) {
			setErrorMessage(
				`Lütfen ${currentLeague.toUpperCase()} liginde 36 takım seçiniz!`
			);
			setErrorMessageType('error');
			return;
		}

		const userId = user.uid;
		const userTeamsRef = ref(database, `users/${userId}`);
		const userFirestoreDocRef = doc(firestore, 'users', userId); // Firestore referansı

		get(userTeamsRef).then((snapshot) => {
			if (snapshot.exists()) {
				const userData = snapshot.val();
				const currentLeagueTeams =
					userData[`${currentLeague}Teams`] || [];
				if (
					JSON.stringify(currentLeagueTeams) ===
					JSON.stringify(selectedTeams)
				) {
					setErrorMessage(getText('noChanges'));
					setErrorMessageType('warning');
					return;
				}

				const updatedData = {
					...userData,
					[`${currentLeague}Teams`]: selectedTeams,
				};

				// Hem Realtime Database hem de Firestore'a veri güncelle
				update(userTeamsRef, updatedData)
					.then(() => {
						return setDoc(userFirestoreDocRef, updatedData, {
							merge: true,
						});
					})
					.then(() => {
						setErrorMessage(
							`${currentLeague.toUpperCase()} ${getText(
								'dataUpdated'
							)}`
						);
						setErrorMessageType('success');
					})
					.catch((error) => {
						setErrorMessage('Veri güncellenirken hata oluştu.');
						setErrorMessageType('error');
						console.error('Error updating data:', error);
					});
			}
		});
	};

	const handleSubmit = () => {
		if (!isLoggedIn) {
			alert('Please log in to submit your selections.');
			return;
		}
		if (user) {
			const userId = user.uid;
			const userTeamsRef = ref(database, `users/${userId}`); // Realtime Database referansı
			const userFirestoreDocRef = doc(firestore, 'users', userId); // Firestore referansı

			let selectedTeams;
			let leagueName;

			if (currentLeague === 'ucl') {
				selectedTeams = selectedUCLTeams;
				leagueName = 'uclTeams';
			} else if (currentLeague === 'uel') {
				selectedTeams = selectedUELTeams;
				leagueName = 'uelTeams';
			} else if (currentLeague === 'uecl') {
				selectedTeams = selectedUECLTeams;
				leagueName = 'ueclTeams';
			}

			if (selectedTeams.length !== 36) {
				setErrorMessage(`${getText('select36Teams')} ${leagueName}`);
				setErrorMessageType('error');
				return;
			}

			const updatedData = {
				[leagueName]: selectedTeams,
				displayName: user.displayName, // DisplayName'i kullan (username veya Twitter adı)
			};

			// Konsola log ekleyelim
			console.log('Firestore verisi:', updatedData);

			// Hem Realtime Database hem de Firestore'a veri yaz
			update(userTeamsRef, updatedData)
				.then(() => {
					return setDoc(userFirestoreDocRef, updatedData, {
						merge: true,
					});
				})
				.then(() => {
					setErrorMessage(getText('dataUpdated'));
					setErrorMessageType('success');
					setIsSubmitted((prevState) => ({
						...prevState,
						[currentLeague]: true,
					}));
				})
				.catch((error) => {
					setErrorMessage(getText('dataSaveError'));
					setErrorMessageType('error');
					console.error(
						'Error writing user data to Firestore:',
						error
					);
				});
		}
	};

	const renderTeams = () => {
		// Eğer currentLeague 'ucl' ise TeamGrid'i gösterme
		if (currentLeague === 'ucl') {
			// UCL için teamGrid gösterilmeyecek, boş dönüyoruz
			return null;
		} else if (currentLeague === 'uel') {
			return null;
			// UEL için teamGrid gösterilmeyecek, boş dönüyoruz

			/* <TeamGrid
					teams={data.uel}
					league='uel'
					onTeamSelect={handleTeamSelectUEL}
				/> */
		} else if (currentLeague === 'uecl') {
			return null; /* (
				<TeamGrid
					teams={data.uecl}
					league='uecl'
					onTeamSelect={handleTeamSelectUECL}
				/>
			); */
		}
	};

	const renderButtons = () => {
		// Eğer currentLeague 'ucl' ise butonları gösterme
		if (
			currentLeague === 'ucl' ||
			currentLeague === 'uel' ||
			currentLeague === 'uecl'
		) {
			return null; // UCL ve UEL için butonlar devre dışı bırakılıyor
		}
		return (
			<div className='buttons-container'>
				{/* Clear All Butonu */}
				<button
					onClick={handleClearSelectedTeams}
					className='clear-button'
				>
					<p>{getText('clearAll')}</p>
				</button>
				{/* Gönder butonunu sadece ilgili ligdeki tüm takımlar gönderilmediğinde göster */}
				{!isSubmitted[currentLeague] && (
					<button onClick={handleSubmit} className='submit-button'>
						<p>{getText('submit')}</p>
					</button>
				)}
				{/* Güncelle butonunu sadece veri gönderildikten sonra göster */}
				{isSubmitted[currentLeague] && (
					<button onClick={handleUpdate} className='update-button'>
						<p>{getText('update')}</p>
					</button>
				)}
			</div>
		);
	};

	useEffect(() => {
		const auth = getAuth();
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setIsLoggedIn(true);
				dispatch({ type: 'SET_USER', payload: user });

				// Kullanıcı oturum açmışsa verilerini çek
				fetchUserData(user.uid);
			} else {
				setIsLoggedIn(false);
			}
		});

		return () => unsubscribe();
	}, [dispatch]); // Sayfa yüklendiğinde sadece bir kez çalışır.

	const fetchUserData = (userId) => {
		const userDocRef = doc(firestore, 'users', userId);
		onSnapshot(userDocRef, (snapshot) => {
			if (snapshot.exists()) {
				const userData = snapshot.data();
				const weeklyResults = userData.weeklyResults || {};

				// UCL Rank and Points
				const uclScore = weeklyResults.uclScore || 0;
				const uclRank =
					uclScore > 0
						? userData.uclRank || 'No Rank Available'
						: null;

				// UEL Rank and Points
				const uelScore = weeklyResults.uelScore || 0;
				const uelRank =
					uelScore > 0
						? userData.uelRank || 'No Rank Available'
						: null;

				// UECL Rank and Points
				const ueclScore = weeklyResults.ueclScore || 0;
				const ueclRank =
					ueclScore > 0
						? userData.ueclRank || 'No Rank Available'
						: null;

				// Set the user scores and ranks independently
				setUserScores({
					uclScore,
					uelScore,
					ueclScore,
					uclRank,
					uelRank,
					ueclRank,
				});
			}
		});
	};

	const renderSelectedTeams = () => {
		const teamsToRender =
			currentLeague === 'ucl'
				? selectedUCLTeams || []
				: currentLeague === 'uel'
				? selectedUELTeams || []
				: selectedUECLTeams || [];

		// UCL ve UEL için DragDrop işlemi devre dışı bırakılacak
		const isDragDisabled =
			currentLeague === 'ucl' || currentLeague === 'uel';

		// Dil kontrolüne göre başlık seçimi
		const predictionTitle =
			language === 'TR' ? 'Sıralama Tahmini' : 'Your Prediction ';

		return (
			<>
				{/* Dil durumuna göre başlık */}
				<h3 className='prediction-title'>{predictionTitle}</h3>

				<DragDropContext
					onDragEnd={
						!isDragDisabled
							? currentLeague === 'ucl'
								? onDragEndUCL
								: currentLeague === 'uel'
								? onDragEndUEL
								: onDragEndUECL
							: () => {} // UCL ve UEL için yer değiştirme devre dışı
					}
				>
					<Droppable droppableId='teamsList'>
						{(provided) => (
							<ul
								className='selected-teams-list'
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{Array.from({ length: 36 }, (_, index) => (
									<Draggable
										key={index}
										draggableId={index.toString()}
										index={index}
										isDragDisabled={
											isDragDisabled ||
											!teamsToRender[index]
										}
									>
										{(provided) => {
											const isSelected =
												teamsToRender[index];
											return (
												<li
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={
														isSelected
															? 'team-item selected-team'
															: 'team-item empty-slot'
													}
												>
													{`${index + 1}. ${
														isSelected
															? isSelected
															: 'Empty Slot'
													}`}
												</li>
											);
										}}
									</Draggable>
								))}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			</>
		);
	};

	const getText = (key) => {
		const texts = {
			EN: {
				welcome: 'Welcome',
				clearAll: 'Clear',
				submit: 'Submit',
				update: 'Update',
				howToPlay: 'How to Play',
				uclTeams: 'UCL',
				uelTeams: 'UEL',
				ueclTeams: 'UECL',
				select36Teams: 'Please select 36 teams in the',
				noChanges: 'No changes detected.',
				dataUpdated: 'Teams updated successfully!',
				dataSaveError: 'Error saving data.',
				selectTeamError: 'Please select 36 teams in the',
				userNotLoggedIn: 'User is not logged in.',
				dataFetchError: 'Error fetching user data from Firebase.',
				dataUpdateError: 'Error updating data.',
				teamsCleared: 'All teams have been cleared.',
			},
			TR: {
				welcome: 'Hoşgeldiniz',
				clearAll: 'Temizle',
				submit: 'Gönder',
				update: 'Güncelle',
				howToPlay: 'Nasıl Oynanır',
				uclTeams: 'UCL',
				uelTeams: 'UEL',
				ueclTeams: 'UECL',
				select36Teams: 'Lütfen bu ligde 36 takım seçiniz:',
				noChanges: 'Herhangi bir değişiklik tespit edilmedi.',
				dataUpdated: 'Takımlar başarıyla güncellendi!',
				dataSaveError: 'Veri kaydedilirken hata oluştu.',
				selectTeamError: 'Lütfen bu ligde 36 takım seçiniz:',
				userNotLoggedIn: 'Kullanıcı oturum açmamış.',
				dataFetchError:
					"Firebase'den kullanıcı verileri alınırken hata oluştu.",
				dataUpdateError: 'Veri güncellenirken hata oluştu.',
				teamsCleared: 'Tüm takımlar temizlendi.',
			},
		};
		return texts[language][key];
	};

	return (
		<div className={`main ${getBackgroundClass()}`}>
			<div className='main-container'>
				<div className='content'>
					<div className='button-selection'>
						<button
							className='how-to-play-button'
							onClick={() => setIsModalOpen(true)}
						>
							{language === 'EN'
								? 'How to Play'
								: language === 'TR'
								? 'Nasıl Oynanır'
								: ''}
						</button>
						<button
							className='user-ranks-button'
							onClick={userRanks}
						>
							{language === 'EN'
								? 'User Ranks'
								: language === 'TR'
								? 'Kullanıcı Sıralaması'
								: ''}
						</button>
						{/* Language toggle button */}
					</div>
					<header className='header-container'>
						<GoogleAd dataAdSlot='5482732947' />
						<h1
							className='header-title'
							style={{
								color:
									currentLeague === 'ucl'
										? '#1a58ff'
										: currentLeague === 'uel'
										? '#f05716'
										: '#00bc12',
							}}
						>
							RanK<span className='reverse-k'>K</span>ing
						</h1>
						<p className='header-subtitle'>
							{language === 'EN'
								? 'Welcome'
								: language === 'TR'
								? 'Hoş Geldin'
								: ''}
							, {user ? user.displayName : 'Guest'}
						</p>

						{/* UCL veya UEL ligi seçiliyse sıralama ve puanları göster */}
						{isLoggedIn && (
							<div className='score-rank-container'>
								{/* UCL League */}
								{currentLeague === 'ucl' &&
									userScores.uclScore > 0 && (
										<>
											<p className='ucl-points'>
												{language === 'TR'
													? `Puanınız: ${userScores.uclScore}`
													: `Your Points: ${userScores.uclScore}`}
											</p>
											<p className='ucl-rank'>
												{language === 'TR'
													? `Sıralamanız: ${
															userScores.uclRank ||
															'Sıralama yok'
													  }`
													: `Your Rank: ${
															userScores.uclRank ||
															'No Rank Available'
													  }`}
											</p>
										</>
									)}

								{/* UEL League */}
								{currentLeague === 'uel' &&
									userScores.uelScore > 0 && (
										<>
											<p className='uel-points'>
												{language === 'TR'
													? `Puanınız: ${userScores.uelScore}`
													: `Your Points: ${userScores.uelScore}`}
											</p>
											<p className='uel-rank'>
												{language === 'TR'
													? `Sıralamanız: ${
															userScores.uelRank ||
															'Sıralama yok'
													  }`
													: `Your Rank: ${
															userScores.uelRank ||
															'No Rank Available'
													  }`}
											</p>
										</>
									)}

								{/* UECL League */}
								{currentLeague === 'uecl' &&
									userScores.ueclScore > 0 && (
										<>
											<p className='uecl-points'>
												{language === 'TR'
													? `Puanınız: ${userScores.ueclScore}`
													: `Your Points: ${userScores.ueclScore}`}
											</p>
											<p className='uecl-rank'>
												{language === 'TR'
													? `Sıralamanız: ${
															userScores.ueclRank ||
															'Sıralama yok'
													  }`
													: `Your Rank: ${
															userScores.ueclRank ||
															'No Rank Available'
													  }`}
											</p>
										</>
									)}
							</div>
						)}
					</header>

					<div className='league-selection'>
						<h2
							className={`league-title ${
								currentLeague === 'ucl' ? 'active ucl' : ''
							}`}
							onClick={() => setCurrentLeague('ucl')}
						>
							{getText('uclTeams')}
						</h2>
						<h2
							className={`league-title ${
								currentLeague === 'uel' ? 'active uel' : ''
							}`}
							onClick={() => setCurrentLeague('uel')}
						>
							{getText('uelTeams')}
						</h2>
						<h2
							className={`league-title ${
								currentLeague === 'uecl' ? 'active uecl' : ''
							}`}
							onClick={() => setCurrentLeague('uecl')}
						>
							{getText('ueclTeams')}
						</h2>
					</div>

					{/* Takımları render etme kısmı */}
					{renderTeams()}
				</div>

				{/* Butonları render etme kısmı */}
				{renderButtons()}

				{errorMessage && (
					<div
						className={`message ${
							errorMessageType === 'success'
								? 'success-s'
								: errorMessageType === 'warning'
								? 'warning-message'
								: ''
						}`}
						role='alert'
					>
						<p>{errorMessage}</p>
					</div>
				)}

				<div className='result'>
					<div className='grid-section'>{renderSelectedTeams()}</div>
				</div>
			</div>

			{isLoggedIn && (
				<button
					onClick={handleLogout}
					className={`logout-button ${currentLeague}-logout-button`}
				>
					<FiLogOut size={44} color='white' />
				</button>
			)}

			{!isLoggedIn && (
				<button
					onClick={handleLoginClick}
					className={`login-button ${currentLeague}-login-button`}
				>
					<FiLogIn size={44} color='white' />
				</button>
			)}

			{/* 			<div className='header-buttons'>
				<button
					className='how-to-play-button'
					onClick={() => setIsModalOpen(true)}
				>
					{language === 'EN'
						? 'How to Play'
						: language === 'TR'
						? 'Nasıl Oynanır'
						: ''}
				</button>
				<button className='user-ranks-button' onClick={userRanks}>
					{language === 'EN'
						? 'User Ranks'
						: language === 'TR'
						? 'Kullanıcı Sıralaması'
						: ''}
				</button>

				<button onClick={toggleDropdown} className='language-button'>
					{language}
				</button>

				{isDropdownOpen && (
					<ul className='dropdown-menu'>
						<li onClick={() => handleLanguageChange('EN')}>EN</li>
						<li onClick={() => handleLanguageChange('TR')}>TR</li>
					</ul>
				)}
			</div> */}

			<HowToPlayModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
		</div>
	);
};

export default HomeScreen;
