import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeScreen from '../screens/HomeScreen';
import LoginScreen from '../screens/LoginScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import NotFound from '../components/NotFound';
import RankScreen from '../screens/RanksScreen';
import Layout from '../components/Layout'; // Layout bileşenini import et
import PrivacyPolicy from '../components/PrivacyPolicy';
import MainScreen from '../screens/MainScreen';
import DataScreen from '../screens/DataScreen';
import ScrollToTop from '../context/ScrollToTop';
const MainRouter = () => {
	return (
		<Router>
			<ScrollToTop /> {/* Place ScrollToTop here */}
			<Routes>
				{/* Public Routes */}
				<Route
					path='/login'
					element={
						<Layout>
							<LoginScreen />
						</Layout>
					}
				/>
				<Route
					path='/'
					element={
						<Layout>
							<MainScreen />
						</Layout>
					}
				/>

				{/* Reset Password Route */}
				<Route
					path='/reset-password'
					element={
						<Layout>
							<ResetPasswordScreen />
						</Layout>
					}
				/>

				{/* Protected Routes */}
				<Route
					path='/ranks'
					element={
						<Layout>
							<RankScreen />
						</Layout>
					}
				/>
				{/* Protected Routes */}
				<Route
					path='/privacy-policy'
					element={
						<Layout>
							<PrivacyPolicy />
						</Layout>
					}
				/>
				<Route
					path='/rank-king'
					element={
						<Layout>
							<HomeScreen />
						</Layout>
					}
				/>
				<Route
					path='/country-score'
					element={
						<Layout>
							<DataScreen />
						</Layout>
					}
				/>

				{/* 404 Not Found Route */}
				<Route
					path='*'
					element={
						<Layout>
							<NotFound />
						</Layout>
					}
				/>
			</Routes>
		</Router>
	);
};

export default MainRouter;
