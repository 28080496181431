// src/context/AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase-config';

const AuthContext = createContext();

export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);

	const logout = () => {
		signOut(auth)
			.then(() => {
				setCurrentUser(null);
			})
			.catch((error) => {
				console.error('Logout Error:', error);
			});
	};

	const value = {
		currentUser,
		setCurrentUser,
		logout, // Expose the logout function
	};

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};
