import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Header />
			<div style={{ flex: 1 }}>{children}</div> {/* Adjust if needed */}
			<Footer />
		</div>
	);
};

export default Layout;
